import { handleActions } from 'redux-actions'

import { WIDGET_SHOP_APP, RU_LANGUAGE } from '@/constants/names'
import {
    SELECT_LANGUAGE,
    USER_PROFILE_TOGGLE,
    SETTINGS_TOGGLE,
    ALARMS_WIDGET_SETTINGS_TOGGLE,
    LOCATION_CHANGE,
    SELECT_WIDGET_SHOP_VALUE,
    TOGGLE_MENU,
    TOGGLE_TREE,
    SET_GLOBAL_LOADING,
    SET_SEARCH_TREE,
    SET_MOBILE,
    GET_APPLICATIONS_VERSION,
    GET_ALL_APPLICATIONS_FILES,
} from '@/store/actions/appSettings'
import {
  USER_LOGIN,
  USER_LOGOUT,
} from '@/store/actions/user'
import { GET_CURRENT_USER } from '@/store/actions/users'

const initialState = {
// language: navigator.language.split(/[-_]/)[0] || RU_LANGUAGE,
  language: RU_LANGUAGE,
  widgetShopValue: WIDGET_SHOP_APP,
  isProfileOpen: false,
  isSettingsOpen: false,
  isAlarmsWidgetSettingsOpen: false,
  isMenuOpen: false,
  isTreeOpen: false,
  notificationsCount: 0,
  user: {},
  globalLoading: false,
  searchTree: [],
  isMobile: null,
  applicationsVersion: [],
  applicationsFiles: [],
}

export const reducer = handleActions(
  {
    [TOGGLE_MENU]: (state, { payload }) => ({
      ...state,
      isMenuOpen: payload,
    }),
    [TOGGLE_TREE]: (state, { payload }) => ({
      ...state,
      isTreeOpen: payload,
    }),
    [SET_MOBILE]: (state, { payload }) => ({
      ...state,
      isMobile: payload,
    }),
    [SELECT_WIDGET_SHOP_VALUE]: (state, { payload }) => ({
      ...state,
      widgetShopValue: payload,
    }),
    [GET_CURRENT_USER.SUCCESS]: (state, { payload }) => ({
      ...state,
      user: payload,
    }),
    [SELECT_LANGUAGE]: (state, { payload: { language } }) => ({
      ...state,
      language,
    }),
    [USER_PROFILE_TOGGLE]: (state, { payload: { isOpen } }) => ({
      ...state,
      isProfileOpen: isOpen,
      isSettingsOpen: false,
      isAlarmsWidgetSettingsOpen: false,
    }),
    [SETTINGS_TOGGLE]: (state, { payload: { isOpen } }) => ({
      ...state,
      isSettingsOpen: isOpen,
      isProfileOpen: false,
      isAlarmsWidgetSettingsOpen: false,
    }),
    [ALARMS_WIDGET_SETTINGS_TOGGLE]: (state, { payload: { isOpen } }) => ({
      ...state,
      isAlarmsWidgetSettingsOpen: isOpen,
      isProfileOpen: false,
      isSettingsOpen: false,
    }),
    [USER_LOGIN.SUCCESS]: (state, { payload: { data } }) => ({
      ...state,
      user: data.user,
    }),
    [USER_LOGOUT.SUCCESS]: (state) => ({
      ...state,
      user: {},
    }),
    [LOCATION_CHANGE]: (state) => ({
      ...state,
      isSettingsOpen: false,
      isProfileOpen: false,
      isAlarmsWidgetSettingsOpen: false,
      globalLoading: false,
    }),
    [SET_GLOBAL_LOADING]: (state, { payload }) => ({
      ...state,
      globalLoading: payload,
    }),
    [SET_SEARCH_TREE]: (state, { payload }) => ({
      ...state,
      searchTree: payload,
    }),
    [GET_APPLICATIONS_VERSION.SUCCESS]: (state, { payload }) => ({
      ...state,
      applicationsVersion: payload,
    }),
    [GET_ALL_APPLICATIONS_FILES.SUCCESS]: (state, { payload }) => ({
      ...state,
      applicationsFiles: payload,
    }),
  },
  initialState,
)

export default reducer
