import React from 'react'
import {Header, TitlePage, ControlsContainer, ButtonBackWraper, CustomTextControl, Container, StyledLink} from './styles'
import ControlsBlock from '@/components/blocks/HeaderPageMobile/ControlsBlock'
import {
  SUMMARY_ALARMS_ANALYTICS_URL,
} from '@/constants/routes'
import GlobalPopup from '@/components/blocks/GlobalPopup'

import BackIcon from '@/components/icons/maps/BackIcon'
import UsersIcons from '@/components/icons/users'
import SearchSwiper from '@/components/blocks/SearchSwiper'
import SwiperSD from '@/components/blocks/SwiperSD'



const HeaderMobile = (
  {
    title,
    controls,
    buttonBack,
    onClose,
    content,
    opacyti,
    openPopup,
    data,
    onChange,
    checkBoxOption,
    value,
    searchActive,
    onChangeInput,
    resetSearch,
    swiperSD,
    infoCards,
  }) => {


  return (
    <>
    {openPopup && 
      <GlobalPopup
      content={content}
      opacyti={opacyti}
    />
    }
    <Container withBorder>
      <Header>
          {buttonBack && 
            <StyledLink to ={SUMMARY_ALARMS_ANALYTICS_URL} onClick={resetSearch}>
              <BackIcon color={'#1061DF'}/>
            </StyledLink>
          }
          {!buttonBack && onClose && 
            <ButtonBackWraper>
              <BackIcon onClick={onClose} color={'#1061DF'}/>
            </ButtonBackWraper>
          }
          <TitlePage buttonBack={onClose}>{title}</TitlePage> 
          <ControlsContainer>
              <ControlsBlock
                controls={controls}
                spaced
              />
          </ControlsContainer>
        </Header>
        {searchActive &&
          <CustomTextControl
          dark
          placeholder="Найти..."
          name="search"
          value={value}
          icon={UsersIcons.MagnifierIcon}
          onChange={onChangeInput}
          resetButton
          resetField={resetSearch}
          />
        }
        {data && <SearchSwiper data={data} onChange={onChange} checkBoxOption={checkBoxOption}/>}
        {swiperSD && <SwiperSD infoCards={infoCards}/>}
    </Container>
  
    </>
  )
}

export default HeaderMobile