import React, { useCallback, useMemo, useState } from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import {
  OK,
  WARNING,
  ERROR,
} from '@/constants/alarms'
import Loader from '@/components/blocks/Loader'
import TyumenOurHomeFilter from '@/forms/TyumenOurHomeFilter'
import Header from '../Header'
import NotificationsColumn from '../NotificationsColumn'
import useGetIsMobile from '@/hooks/useGetIsMobile'
import EmptyDataState from "components/blocks/EmptyDataState";
import {
  WidgetContainer,
  Container,
} from './styles'

const NOTIFICATTIONS_COLUMNS = [
  {
    title: <Lang id="widgets.solving" />,
    type: WARNING,
  },
  {
    title: <Lang id="widgets.failOrNoProblem" />,
    type: ERROR,
  },
  {
    title: <Lang id="widgets.resolved" />,
    type: OK,
  },
]

const Treatment = ({
  data,
  selectedMessageId,
  messageData,
  isLoading,
  dataMobile,

  setSelectedElement,

  getMessage,
  getMessagesList,
  test
}) => {

  const { search } = useLocation()
  const [selectedStatus] = useState(null)
  const parsedSearch = useMemo(() => qs.parse(search), [search])
  const isMobile = useGetIsMobile()
  const [type, setType] = useState(null)
  const getMessagesListRequest = useCallback(() => {
    getMessagesList(parsedSearch)
  }, [getMessagesList, parsedSearch])

  const onSelectNotification = useCallback((id) => {
    setSelectedElement(id)
    getMessage({ ...parsedSearch, id })
  }, [setSelectedElement, parsedSearch, getMessage])

  const onCloseNotifications = useCallback(() => {
    setSelectedElement(null)
  }, [setSelectedElement])

  const renderNotificationsColumns = () => {
    if (
      isLoading
      && Object.keys(data).every((key) => data[key].length === 0)
    ) {
      return <Loader center />
    }

    if (
      !isLoading
      && Object.keys(data).every((key) => data[key].length === 0)
    ) {
      return <EmptyDataState />
    }

    const NotificationsColumns = isMobile ? [NOTIFICATTIONS_COLUMNS[0]] : NOTIFICATTIONS_COLUMNS
    return NotificationsColumns.map((column) => {
      if (!selectedStatus || (selectedStatus && selectedStatus === column.type)) {
        return (
          <NotificationsColumn
            {...column}
            data={isMobile ? (type ? data[type] : dataMobile ) : data[column.type]}
            onNotificationClick={onSelectNotification}
            isMobile={isMobile}
            type={type}
          />
        )
      }
      return null
    })
  }
  const renderNotificationMessage = () => {
    if (selectedMessageId) {
      if (isLoading && !messageData.key) {
        return <Loader center />
      }

      return (
        <NotificationsColumn
          large
          data={messageData}
          onClose={onCloseNotifications}
          isMobile={isMobile}
        />
      )
    }

    return null
  }

  return (
    <Container>
      <TyumenOurHomeFilter
        getMessagesListRequest={getMessagesListRequest}
        isMobile={isMobile}
        selectedMessageId={selectedMessageId}
        data={messageData}
        counts={data}
        onClose={onCloseNotifications}
        type={type}
        setType={setType}
        content={!selectedMessageId && (
          <Header
            query={parsedSearch.query}
            isMobile={isMobile}
          />
        )}
      />
      {isMobile ?    
        <WidgetContainer>
          {selectedMessageId ? renderNotificationMessage() : renderNotificationsColumns()}
        </WidgetContainer>
      :
        <WidgetContainer>
          {renderNotificationsColumns()}
          {renderNotificationMessage()}
        </WidgetContainer>
      }
    </Container>
  )
}

Treatment.propTypes = {
}
Treatment.defaultProps = {
}

export default Treatment
