export default {
  catalogs: {
    // controller passport
    GENERAL_INFORMATION: 'общая информация',
    TECHNICAL_CONSTRAINTS: 'технические параметры',
    EXPLOITATION: 'эксплуатация',
    ATTACHMENT: 'вложения',
    // controller card
    LOCATION: 'местоположение',
    OBJECT_CHARACTERISTIC: 'характеристики объекта',
    SURVEY_DATA: 'данные из обследования',
    SERVICING: 'обслуживание объекта',
    MANAGEMENT_ORGANIZATION_SYSTEM: 'Система организации управления'

  },
  fields: {
    'LOCATION.PROJECT': 'Проект',
    'PROJECT': 'Проект',
    'LOCATION.LATITUDE': 'Географическая широта',
    'LATITUDE': 'Географическая широта',
    'LOCATION.LONGITUDE': 'Географическая долгота',
    'LONGITUDE': 'Географическая долгота',
    'LOCATION.REGION': 'Регион',
    'REGION': 'Регион',
    'LOCATION.CITY': 'Город',
    'CITY': 'Город',
    'LOCATION.STREET': 'Улица',
    'STREET': 'Улица',
    'LOCATION.HOME_NUMBER': 'Дом',
    'HOME_NUMBER': 'Дом',


    'OBJECT_CHARACTERISTIC.EQUIPMENT_TYPE': 'Тип оборудования',
    'EQUIPMENT_TYPE': 'Тип оборудования',
    'OBJECT_CHARACTERISTIC.lampType': 'Тип светильника',
    'lampType': 'Тип светильника',
    'OBJECT_CHARACTERISTIC.LIGHTING_CATEGORY': 'Категория объекта освещения',
    'LIGHTING_CATEGORY': 'Категория объекта освещения',
    'OBJECT_CHARACTERISTIC.IMPORTANCE': 'Важность объекта',
    'IMPORTANCE': 'Важность объекта',
    'OBJECT_CHARACTERISTIC.WORK_MODE': 'Режим работы',
    'WORK_MODE': 'Режим работы',
    'OBJECT_CHARACTERISTIC.INSTALLATION_TYPE': 'Тип установки',
    'INSTALLATION_TYPE': 'Тип установки',
    'OBJECT_CHARACTERISTIC.CONTROL_TYPE': 'Тип управления',
    'CONTROL_TYPE': 'Тип управления',
    'OBJECT_CHARACTERISTIC.LIGHTING_TYPE': 'Тип объекта освещения',
    'LIGHTING_TYPE': 'Тип объекта освещения',
    'OBJECT_CHARACTERISTIC.OUTGOING_LINES_NUMBER': 'Количество отходящих линий',
    'OUTGOING_LINES_NUMBER': 'Количество отходящих линий',
    'OBJECT_CHARACTERISTIC.SETUP': 'Настройка',
    'SETUP': 'Настройка',
    'OBJECT_CHARACTERISTIC.INDICATORS': 'Индикаторы',
    'INDICATORS': 'Индикаторы',
    'OBJECT_CHARACTERISTIC.CONNECTED_FIXTURES_NUMBER': 'Количество подключенных осветительных приборов',
    'CONNECTED_FIXTURES_NUMBER': 'Количество подключенных осветительных приборов',
    'OBJECT_CHARACTERISTIC.LIGHT_FUNCTIONALITY': 'Функционал освещения',
    'LIGHT_FUNCTIONALITY': 'Функционал освещения',
    'OBJECT_CHARACTERISTIC.LOCATION_TYPE': 'Признак пешеходного пространства',
    'OBJECT_CHARACTERISTIC.CONNECTED_LAMP_NUMBER': 'Количество подключенных осветительных приборов',


    'SURVEY_DATA.CONNECTED_LAMP_NUMBER': 'Количество подключенных осветительных приборов',
    'CONNECTED_LAMP_NUMBER': 'Количество подключенных осветительных приборов',
    'SURVEY_DATA.LOCATION_TYPE': 'Признак пешеходного пространства',
    'LOCATION_TYPE': 'Признак пешеходного пространства',
    'SURVEY_DATA.LAMP_INSTALLATION_HEIGHT': 'Высота установки светильника в метрах',
    'LAMP_INSTALLATION_HEIGHT': 'Высота установки светильника в метрах',
    'SURVEY_DATA.ROADWAY_WIDTH': 'Ширина проезжей части в метрах',
    'ROADWAY_WIDTH': 'Ширина проезжей части в метрах',
    'SURVEY_DATA.INSTALLATION_SCHEME': 'Схема установки опоры',
    'INSTALLATION_SCHEME': 'Схема установки опоры',
    'SURVEY_DATA.STATE': 'Состояние опоры',
    'STATE': 'Состояние опоры',
    'SURVEY_DATA.POWER_SUPPLY': 'Питание',
    'POWER_SUPPLY': 'Питание',
    'SURVEY_DATA.POWER_SUPPLY_TYPE': 'Тип питания',
    'POWER_SUPPLY_TYPE': 'Тип питания',
    'SURVEY_DATA.OVERHANG_HEIGHT': 'Свес световой точки в метрах',
    'OVERHANG_HEIGHT': 'Свес световой точки в метрах',
    'SURVEY_DATA.LAMP_ANGLE': 'Наклон световой точки в градусах',
    'LAMP_ANGLE': 'Наклон световой точки в градусах',
    'SURVEY_DATA.OVERHANG_LENGTH': 'Длина консоли в метрах',
    'OVERHANG_LENGTH': 'Длина консоли в метрах',
    'SURVEY_DATA.UTILITY_POLE_LAMP_POSITION': 'Позиция светильника в плане на опоре в градусках',
    'UTILITY_POLE_LAMP_POSITION': 'Позиция светильника в плане на опоре в градусках',
    'SURVEY_DATA.CITY_NUMERIC': 'Нумерация города',
    'CITY_NUMERIC': 'Нумерация города',
    'SURVEY_DATA.UTILITY_POLE_TYPE': 'Тип опоры',
    'UTILITY_POLE_TYPE': 'Тип опоры',
    'SURVEY_DATA.UTILITY_POLE_KIND': 'Вид опоры',
    'UTILITY_POLE_KIND': 'Вид опоры',
    'SURVEY_DATA.VOLS': 'ВОЛС',
    'VOLS': 'ВОЛС',
    'SURVEY_DATA.OBJECT_ATTRIBUTE': 'Признак объекта',
    'OBJECT_ATTRIBUTE': 'Признак объекта',
    'SURVEY_DATA.BELONGING': 'Принадлежность',
    'BELONGING': 'Принадлежность',
    'SURVEY_DATA.CONNECTED_FIXTURES_NUMBER': 'Количество подключенных осветительных приборов',
    'SURVEY_DATA.LAMP_TYPE': 'Тип светильника',
    'LAMP_TYPE': 'Тип светильника',
    'SURVEY_DATA.LIGHT_FIXTURE_STATE': 'Состояние светильника',
    'LIGHT_FIXTURE_STATE': 'Состояние светильника',
    'SURVEY_DATA.CONSOLE': 'Консоль',
    'CONSOLE': 'Консоль',
    'SURVEY_DATA.NETWORK_COMPANY': 'Сетевая компания',
    'NETWORK_COMPANY': 'Сетевая компания',


    'SERVICING.INSPECTION_DATE': 'Дата осмотра',
    'INSPECTION_DATE': 'Дата осмотра',
    'SERVICING.BYPASS_DATE': 'Дата объезда',
    'BYPASS_DATE': 'Дата объезда',
    'SERVICING.MAINTENANCE_DATE': 'Дата технического обслуживания',
    'MAINTENANCE_DATE': 'Дата технического обслуживания',
    'SERVICING.REPAIR_DATE': 'Дата ремонта',
    'REPAIR_DATE': 'Дата ремонта',
    'SERVICING.CAPITAL_REPAIR_DATE': 'Дата капитального ремонта',
    'CAPITAL_REPAIR_DATE': 'Дата капитального ремонта',
    'SERVICING.GUARANTEE_EXPIRATION_DATE': 'Дата окончания гарантии',
    'GUARANTEE_EXPIRATION_DATE': 'Дата окончания гарантии',
    'SERVICING.CHECKING_DATE': 'Дата поверки',
    'CHECKING_DATE': 'Дата поверки',
    'SERVICING.PLAN_PPR_DATE': 'Дата плана ППР',
    'PLAN_PPR_DATE': 'Дата плана ППР',
    'SERVICING.FACT_PPR_DATE': 'Дата факта ППР',
    'FACT_PPR_DATE': 'Дата факта ППР',
    'SERVICING.MODERNIZATION': 'Модернизация',
    'MODERNIZATION': 'Модернизация',


    'GENERAL_INFORMATION.IDENTIFIER': 'Идентификатор',
    'IDENTIFIER': 'Идентификатор',
    'GENERAL_INFORMATION.NAME': 'Название',
    'NAME': 'Название',
    'GENERAL_INFORMATION.INSTALLATION': 'Монтаж',
    'INSTALLATION': 'Монтаж',
    'GENERAL_INFORMATION.EQUIPMENT_TYPE': 'Тип оборудования',
    'GENERAL_INFORMATION.ROOT_OBJECT': 'Корневой объект',
    'ROOT_OBJECT': 'Корневой объект',
    'GENERAL_INFORMATION.DEVICE_SERIAL_NUMBER': 'Серийный номер',
    'DEVICE_SERIAL_NUMBER': 'Серийный номер',
    'GENERAL_INFORMATION.METER_SN': 'Счетчик №',
    'METER_SN': 'Счетчик №',
    'GENERAL_INFORMATION.TIME_ZONE': 'Временная зона устройства',
    'TIME_ZONE': 'Временная зона устройства',
    'GENERAL_INFORMATION.CONN_TYPE': 'Тип передачи данных',
    'CONN_TYPE': 'Тип передачи данных',
    'GENERAL_INFORMATION.SWVER': 'Версия прошивки',
    'SWVER': 'Версия прошивки',
    'GENERAL_INFORMATION.ICCID': 'Идентификационный номер Sim-карты',
    'ICCID': 'Идентификационный номер Sim-карты',
    'GENERAL_INFORMATION.ICCID1': 'Идентификационный номер Sim-карты слот 1',
    'ICCID1': 'Идентификационный номер Sim-карты слот 1',
    'GENERAL_INFORMATION.ICCID2': 'Идентификационный номер Sim-карты слот 2',
    'ICCID2': 'Идентификационный номер Sim-карты слот 2',
    'GENERAL_INFORMATION.VERSION_API': 'Версия ПО (API)',
    'VERSION_API': 'Версия ПО (API)',
    'GENERAL_INFORMATION.UTILITY_POLE_TYPE': 'Тип опоры',
    'GENERAL_INFORMATION.MODEL_MARK': 'Модель',
    'MODEL_MARK': 'Модель',
    'GENERAL_INFORMATION.LAMP_TYPE': 'Тип светильника',
    'GENERAL_INFORMATION.PROCEDURE_SUPPLIER': 'Производитель/поставщик',
    'PROCEDURE_SUPPLIER': 'Производитель/поставщик',
    'GENERAL_INFORMATION.PRODUCER': 'Производитель',
    'PRODUCER': 'Производитель',
    'GENERAL_INFORMATION.SUPPLIER': 'Поставщик',
    'SUPPLIER': 'Поставщик',
    'GENERAL_INFORMATION.LIGHTING_CONTROL_BOX_IDENTIFIER': 'Идентификатор ШУО',
    'LIGHTING_CONTROL_BOX_IDENTIFIER': 'Идентификатор ШУО',
    'GENERAL_INFORMATION.AUTOMATED_CONTROL_SYSTEM_IDENTIFIER': 'Идентификатор АСУ',
    'AUTOMATED_CONTROL_SYSTEM_IDENTIFIER': 'Идентификатор АСУ',
    'GENERAL_INFORMATION.LAMP_IDENTIFIER': 'Идентификатор светильника',
    'LAMP_IDENTIFIER': 'Идентификатор светильника',
    'GENERAL_INFORMATION.PARENT_IDENTIFIER': 'Идентификатор родителя',
    'PARENT_IDENTIFIER': 'Идентификатор родителя',
    'GENERAL_INFORMATION.PARENT_TYPE': 'Тип родителя',
    'PARENT_TYPE': 'Тип родителя',
    'GENERAL_INFORMATION.LINE_IDENTIFIER': 'Идентификатор линии',
    'LINE_IDENTIFIER': 'Идентификатор линии',
    'GENERAL_INFORMATION.BASE_STATION_IDENTIFIER': 'Индентификатор базовой станции',
    'BASE_STATION_IDENTIFIER': 'Индентификатор базовой станции',
    'GENERAL_INFORMATION.UTILITY_POLE_IDENTIFIER': 'Индентификатор опоры',
    'UTILITY_POLE_IDENTIFIER': 'Индентификатор опоры',
    'GENERAL_INFORMATION.REGISTRY_NUMBER': 'Реестровый номер',
    'REGISTRY_NUMBER': 'Реестровый номер',
    'GENERAL_INFORMATION.REGISTRY_NUMBER_ASUNO': 'Реестровый номер АСУНО',
    'REGISTRY_NUMBER_ASUNO': 'Реестровый номер АСУНО',
    'GENERAL_INFORMATION.PREVIOUS_LINKED_IDENTIFIER': 'Связь объекта',
    'PREVIOUS_LINKED_IDENTIFIER': 'Связь объекта',
    'GENERAL_INFORMATION.CONTROL_CUPBOARD_TYPE': 'Тип ШУО',
    'CONTROL_CUPBOARD_TYPE': 'Тип ШУО',
    'GENERAL_INFORMATION.CUPBOARD_SERIAL_NUMBER': 'Заводской номер шкафа',
    'CUPBOARD_SERIAL_NUMBER': 'Заводской номер шкафа',
    'GENERAL_INFORMATION.CONTROLLER_MODEL': 'Модель контроллера',
    'CONTROLLER_MODEL': 'Модель контроллера',
    'GENERAL_INFORMATION.CONTROLLER_SERIAL_NUMBER': 'Серийный номер контроллера',
    'CONTROLLER_SERIAL_NUMBER': 'Серийный номер контроллера',
    'GENERAL_INFORMATION.MODEL_CONTROL_CUPBOARD_COUNTER': 'Модель счетчика ШУО',
    'MODEL_CONTROL_CUPBOARD_COUNTER': 'Модель счетчика ШУО',
    'GENERAL_INFORMATION.SERIAL_NUMBER_CONTROL_CUPBOARD_COUNTER': 'Серийный номер счетчика ШУО',
    'SERIAL_NUMBER_CONTROL_CUPBOARD_COUNTER': 'Серийный номер счетчика ШУО',
    'GENERAL_INFORMATION.COUNTER_MODEL_DEK': 'Модель счетчика ДЭК',
    'COUNTER_MODEL_DEK': 'Модель счетчика ДЭК',
    'GENERAL_INFORMATION.SERIAL_NUMBER_DEK_COUNTER': 'Серийный номер счетчика ДЭК',
    'SERIAL_NUMBER_DEK_COUNTER': 'Серийный номер счетчика ДЭК',
    'GENERAL_INFORMATION.BASE_STATION': 'Базовая станция',
    'BASE_STATION': 'Базовая станция',
    'GENERAL_INFORMATION.CONNECTOR': 'Разъем',
    'CONNECTOR': 'Разъем',
    'GENERAL_INFORMATION.MODEL': 'Модель',
    'MODEL': 'Модель',
    'GENERAL_INFORMATION.INCOMING_COUNTER_MODEL': 'Модель счетчика входящего',
    'INCOMING_COUNTER_MODEL': 'Модель счетчика входящего',
    'GENERAL_INFORMATION.SERIAL_NUMBER_INCOMING_COUNTER': 'Серийный номер счетчика входящего',
    'SERIAL_NUMBER_INCOMING_COUNTER': 'Серийный номер счетчика входящего',
    'GENERAL_INFORMATION.OUTGOING_COUNTER_MODEL': 'Модель счетчика исходящего',
    'OUTGOING_COUNTER_MODEL': 'Модель счетчика исходящего',
    'GENERAL_INFORMATION.SERIAL_NUMBER_OUTGOING_COUNTER': 'Серийный номер счетчика исходящего',
    'SERIAL_NUMBER_OUTGOING_COUNTER': 'Серийный номер счетчика исходящего',


    'TECHNICAL_CONSTRAINTS.WORKING_TEMPERATURE_RANGE': 'Диапазон рабочей температуры, B',
    'WORKING_TEMPERATURE_RANGE': 'Диапазон рабочей температуры',
    'TECHNICAL_CONSTRAINTS.POWER_CONSUMPTION': 'Потребляемая мощность, Вт',
    'POWER_CONSUMPTION': 'Потребляемая мощность, Вт',
    'TECHNICAL_CONSTRAINTS.WORKING_VOLTAGE_RANGE': 'Диапазон рабочего напряжения, B',
    'WORKING_VOLTAGE_RANGE': 'Диапазон рабочего напряжения',
    'TECHNICAL_CONSTRAINTS.LIGHT_OUTPUT': 'Световой поток',
    'LIGHT_OUTPUT': 'Световой поток',
    'TECHNICAL_CONSTRAINTS.CALIBRATION_INTERVAL': 'Межповерочный интервал',
    'CALIBRATION_INTERVAL': 'Межповерочный интервал',
    'TECHNICAL_CONSTRAINTS.COLOUR_TEMPERATURE': 'Цветовая температура',
    'COLOUR_TEMPERATURE': 'Цветовая температура',
    'TECHNICAL_CONSTRAINTS.COLOUR_RENDERING_INDEX': 'Индекс цветопередачи',
    'COLOUR_RENDERING_INDEX': 'Индекс цветопередачи',
    'TECHNICAL_CONSTRAINTS.LAMP_EFFICIENCY': 'Эффективность светильника',
    'LAMP_EFFICIENCY': 'Эффективность светильника',
    'TECHNICAL_CONSTRAINTS.SCATTERING_ANGLE': 'Угол рассеивания',
    'SCATTERING_ANGLE': 'Угол рассеивания',
    'TECHNICAL_CONSTRAINTS.LIGHT_INTENSITY_DISTRIBUTION_CURVE_TYPE': 'Тип КСС',
    'LIGHT_INTENSITY_DISTRIBUTION_CURVE_TYPE': 'Тип КСС',
    'TECHNICAL_CONSTRAINTS.FLICKER_PERCENT': 'Коэффициент пульсации',
    'FLICKER_PERCENT': 'Коэффициент пульсации',
    'TECHNICAL_CONSTRAINTS.RATED_POWER': 'Разрешенная мощность, Вт',
    'RATED_POWER': 'Разрешенная мощность',
    'TECHNICAL_CONSTRAINTS.ELECTRICITY_SUPPLIER': 'Поставщик электрической энергии',
    'ELECTRICITY_SUPPLIER': 'Поставщик электрической энергии',
    'TECHNICAL_CONSTRAINTS.ACTUAL_CONSUMPTION_POWER': 'Фактически подключенная мощность',
    'ACTUAL_CONSUMPTION_POWER': 'Фактически подключенная мощность',
    'TECHNICAL_CONSTRAINTS.NUMBER_MEASUREMENT_CHANNELS': 'Количество каналов измерения',
    'NUMBER_MEASUREMENT_CHANNELS': 'Количество каналов измерения',
    'TECHNICAL_CONSTRAINTS.NUMBER_CONTROLLED_HEATING_SYSTEM': 'Количество контролируемых теплосистем',
    'NUMBER_CONTROLLED_HEATING_SYSTEM': 'Количество контролируемых теплосистем',
    'TECHNICAL_CONSTRAINTS.MEASURING_RANGE_AVERAGE_VOLUMETRIC_FLOW': 'Диапазон измерения среднего объемного расхода',
    'MEASURING_RANGE_AVERAGE_VOLUMETRIC_FLOW': 'Диапазон измерения среднего объемного расхода',
    'TECHNICAL_CONSTRAINTS.TEMPERATURE_MEASUREMENT_RANGE': 'Диапазон измерения температуры',
    'TEMPERATURE_MEASUREMENT_RANGE': 'Диапазон измерения температуры',
    'TECHNICAL_CONSTRAINTS.MEASUREMENT_RANGE_TEMPERATURE_DIFFERENCE_SUPPLY_AND_RETURN_PIPELIN': 'Диапазон измерения разности температур в подающем и обратном трубопроводе',
    'MEASUREMENT_RANGE_TEMPERATURE_DIFFERENCE_SUPPLY_AND_RETURN_PIPELIN': 'Диапазон измерения разности температур в подающем и обратном трубопроводе',
    'TECHNICAL_CONSTRAINTS.PRESSURE_MEASUREMENT_RANGE': 'Диапазон измерения давления',
    'PRESSURE_MEASUREMENT_RANGE': 'Диапазон измерения давления',
    'TECHNICAL_CONSTRAINTS.DC_POWER_SUPPLY_VOLTAGE': 'Напряжение питания постоянного тока',
    'DC_POWER_SUPPLY_VOLTAGE': 'Напряжение питания постоянного тока',
    'TECHNICAL_CONSTRAINTS.LIMITS_PERMISSIBLE_RELATIVE_ERROR_TEMPERATURE_MEASUREMENT': 'Пределы допускаемой относительной погрешности при измерении температуры',
    'LIMITS_PERMISSIBLE_RELATIVE_ERROR_TEMPERATURE_MEASUREMENT': 'Пределы допускаемой относительной погрешности при измерении температуры',
    'TECHNICAL_CONSTRAINTS.LAMP_TYPE': 'Тип светильника',
    'TECHNICAL_CONSTRAINTS.LIGHT_FIXTURE_STATE': 'Состояние светильника',
    'TECHNICAL_CONSTRAINTS.CONSOLE': 'Консоль',
    'TECHNICAL_CONSTRAINTS.HOUSING_MATERIAL': 'Материал корпуса',
    'MATERIAL': 'Материал корпуса',
    'TECHNICAL_CONSTRAINTS.STANDARD_COLOR_RAL': 'Стандартный цвет (RAL)',
    'STANDARD_COLOR_RAL': 'Стандартный цвет (RAL)',
    'TECHNICAL_CONSTRAINTS.UTILITY_POLE_TYPE': 'Тип опоры',
    'TECHNICAL_CONSTRAINTS.COLOR_LAR': 'Цвет (RAL)',
    'COLOR_LAR': 'Цвет (RAL)',
    'TECHNICAL_CONSTRAINTS.SIGN_PAINTING': 'Признак покраски',
    'SIGN_PAINTING': 'Признак покраски',


    'EXPLOITATION.START_DATE': 'Дата начала эксплуатации',
    'START_DATE': 'Дата начала эксплуатации',
    'EXPLOITATION.EXPLOITATION_END_DATE': 'Дата окончания эксплуатации',
    'EXPLOITATION_END_DATE': 'Дата окончания эксплуатации',
    'EXPLOITATION.ORGANIZATION': 'Эксплуатирующая организация',
    'ORGANIZATION': 'Эксплуатирующая организация',
    'EXPLOITATION.OWNER': 'Собственник',
    'OWNER': 'Собственник',
    'EXPLOITATION.BALANCE_SHEET_HOLDER': 'Балансодержатель',
    'BALANCE_SHEET_HOLDER': 'Балансодержатель',
    'EXPLOITATION.GUARANTEE_PERIOD': 'Гарантийный срок, г',
    'GUARANTEE_PERIOD': 'Гарантийный срок',
    'EXPLOITATION.LIFE_TIME': 'Срок службы, г',
    'LIFE_TIME': 'Срок службы',
    'EXPLOITATION.PRODUCER_WARRANTY_EXPIRATION_DATE': 'Дата окончания гарантии от производителя',
    'PRODUCER_WARRANTY_EXPIRATION_DATE': 'Дата окончания гарантии от производителя',
    'EXPLOITATION.SUPPLIER_WARRANTY_EXPIRATION_DATE': 'Дата окончания гарантии от поставщика',
    'SUPPLIER_WARRANTY_EXPIRATION_DATE': 'Дата окончания гарантии от поставщика',
    'EXPLOITATION.LIGHT_FIXTURE_WORK_RESOURCE': 'Ресурс работы светильника',
    'LIGHT_FIXTURE_WORK_RESOURCE': 'Ресурс работы светильника',
    'EXPLOITATION.MODERNIZATION': 'Модернизация',


    'ATTACHMENT.PHOTO_TITLE': 'Фото',
    'PHOTO_TITLE': 'Фото',
    'ATTACHMENT.DOCUMENT_TITLE': 'Файл паспорта изделия',
    'DOCUMENT_TITLE': 'Файл паспорта изделия',

    'OBJECT_CHARACTERISTIC.TARIFF_ONE': 'Тариф1',
    'TARIFF_ONE': 'Тариф1',
    'OBJECT_CHARACTERISTIC.TARIFF_TWO': 'Тариф2',
    'TARIFF_TWO': 'Тариф2',
    'OBJECT_CHARACTERISTIC.TARIFF_THREE': 'Тариф3',
    'TARIFF_THREE': 'Тариф3',
    'OBJECT_CHARACTERISTIC.TARIFF_FOUR': 'Тариф4',
    'TARIFF_FOUR': 'Тариф4',
    'OBJECT_CHARACTERISTIC.PREVIOUS_LINKED_IDENTIFIER': 'Связь объекта',

    'MANAGEMENT_ORGANIZATION_SYSTEM.CONNECTOR': 'Разъём',
    'MANAGEMENT_ORGANIZATION_SYSTEM.DEVICE_SERIAL_NUMBER': 'Серийный номер устройства',
    'MANAGEMENT_ORGANIZATION_SYSTEM.CONN_TYPE': 'Тип передачи данных',
    'SYSTEM.CONN_TYPE': 'Тип передачи данных',
    'MANAGEMENT_ORGANIZATION_SYSTEM.SWVER': 'Версия прошивки устройства',
    'SYSTEM.SWVER': 'Версия прошивки устройства',
    'MANAGEMENT_ORGANIZATION_SYSTEM.ICCID': 'Идентификационный номер Sim-карты',
    'SYSTEM.ICCID': 'Идентификационный номер Sim-карты',
    'MANAGEMENT_ORGANIZATION_SYSTEM.TIME_ZONE': 'Временная зона устройства',
    'MANAGEMENT_ORGANIZATION_SYSTEM.PRESENCE_BASE_STATION': 'Наличие базовой станции',
    'PRESENCE_BASE_STATION': 'Наличие базовой станции',
    'MANAGEMENT_ORGANIZATION_SYSTEM.CONTROLLER_MODEL': 'Модель контроллера',
    'MANAGEMENT_ORGANIZATION_SYSTEM.CONTROLLER_SERIAL_NUMBER': 'Серийный номер контроллера',
    'MANAGEMENT_ORGANIZATION_SYSTEM.ICCID1': 'Идентификационный номер Sim-карты первого слота',
    'MANAGEMENT_ORGANIZATION_SYSTEM.ICCID2': 'Идентификационный номер Sim-карты второго слота',
  },
  title: 'Паспортизация',
  changeTitle: 'Сохранить изменения?',
  passportSidebar: {
    equipment: 'оборудование инсталляции',
    discardAll: 'Отметить все',
    passport: 'Паспорт',
    card: 'Карточка',
    view: 'Просмотр:',
    noImage: 'изображение отсутствует',
    passportExport: 'экспорт паспорта',
    fieldSettings: 'Настройки отображения полей:',
    yes: 'Да',
    no: 'Нет',
  },
}
