import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

export default [
  {
    passportParameter: <Lang id="passportization.fields.generalInfo.name" />,
    showInDashboard: true,
    selector: 'name',
  },
  {
    passportParameter: <Lang id="passportization.fields.generalInfo.identifier" />,
    showInDashboard: true,
    selector: 'objectIdOuter',
  },
  {
    passportParameter: <Lang id="passportization.fields.generalInfo.equipmentType" />,
    showInDashboard: true,
    selector: 'equipmentType',
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.MODEL_MARK" />,
    showInDashboard: true,
    selector: 'MODEL_MARK',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PROCEDURE_SUPPLIER" />,
    showInDashboard: true,
    selector: 'PROCEDURE_SUPPLIER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.ROOT_OBJECT" />,
    showInDashboard: true,
    selector: 'ROOT_OBJECT',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PARENT_TYPE" />,
    showInDashboard: true,
    selector: 'PARENT_TYPE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PARENT_IDENTIFIER" />,
    showInDashboard: true,
    selector: 'PARENT_IDENTIFIER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.REGISTRY_NUMBER" />,
    showInDashboard: true,
    selector: 'REGISTRY_NUMBER',
    isCard: true
  },

  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.WORKING_TEMPERATURE_RANGE" />,
    showInDashboard: true,
    selector: 'WORKING_TEMPERATURE_RANGE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.WORKING_VOLTAGE_RANGE" />,
    showInDashboard: true,
    selector: 'WORKING_VOLTAGE_RANGE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.POWER_CONSUMPTION" />,
    showInDashboard: true,
    selector: 'POWER_CONSUMPTION',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.START_DATE" />,
    showInDashboard: true,
    selector: 'START_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.EXPLOITATION_END_DATE" />,
    showInDashboard: true,
    selector: 'EXPLOITATION_END_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.ORGANIZATION" />,
    showInDashboard: true,
    selector: 'ORGANIZATION',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.OWNER" />,
    showInDashboard: true,
    selector: 'OWNER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.GUARANTEE_PERIOD" />,
    showInDashboard: true,
    selector: 'GUARANTEE_PERIOD',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.LIFE_TIME" />,
    showInDashboard: true,
    selector: 'LIFE_TIME',
    isCard: true
  },


  {
    passportParameter: <Lang id="passport.fields.LOCATION.PROJECT" />,
    showInDashboard: true,
    selector: 'PROJECT',
    isCard: true
  },
  {
    passportParameter: <Lang id="passportization.fields.location.latitude" />,
    showInDashboard: true,
    selector: 'latitude',
  },
  {
    passportParameter: <Lang id="passportization.fields.location.longitude" />,
    showInDashboard: true,
    selector: 'longitude',
  },

  {
    passportParameter: <Lang id="passport.fields.SERVICING.PLAN_PPR_DATE" />,
    showInDashboard: true,
    selector: 'PLAN_PPR_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.SERVICING.FACT_PPR_DATE" />,
    showInDashboard: true,
    selector: 'FACT_PPR_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.SERVICING.INSPECTION_DATE" />,
    showInDashboard: true,
    selector: 'INSPECTION_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.SERVICING.BYPASS_DATE" />,
    showInDashboard: true,
    selector: 'BYPASS_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.SERVICING.MAINTENANCE_DATE" />,
    showInDashboard: true,
    selector: 'MAINTENANCE_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.SERVICING.REPAIR_DATE" />,
    showInDashboard: true,
    selector: 'REPAIR_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.SERVICING.GUARANTEE_EXPIRATION_DATE" />,
    showInDashboard: true,
    selector: 'GUARANTEE_EXPIRATION_DATE',
    isCard: true
  },
]
