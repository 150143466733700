import React, {
  useCallback,
  useRef,
  useMemo,
  useEffect,
  useState,
} from 'react'
import { Formik } from 'formik'
import pt from 'prop-types'
import moment from 'moment'
import noop from 'lodash/noop'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import { FormattedMessage as Lang } from 'react-intl'
import qs from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'
import SelectField from '@/components/fields/SelectField'
import SelectFieldMobile from '@/components/fields/SelectFieldMobile'
import useUrlParseAndPush from '@/hooks/useUrlParseAndPush'
import getFastInterval from '@/helpers/getFastInterval'
import createNotifications from '@/helpers/notification'
import HeaderMobile from '@/containers/pages/AnalyticsAlarms/components/SummaryAlarmsAnalytics/HeaderMobile/component'
import CoreIcon from '@/components/icons/core'
import HeaderIcons from '@/components/icons/header'
import SortShuterCytiPortal from '@/components/blocks/SortShuterCytiPortal'
import PopupIcons from '@/components/icons/popup'
import SwiperCytiPortal from '@/components/blocks/SwiperCytiPortal'
import {
  START_DATE,
  END_DATE,
  URBAN_PROCESSES,
  CHART_TYPE,
  PERIOD,
  INTERVAL,
  STATUS,
} from '@/constants/forms/tyumenOurHomeFilter'
import {
  TYUMEN_OUR_HOME_URL,
  TYUMEN_OUR_HOME_GRAPHS_URL,
} from '@/constants/routes'
import {
  ALL_CHARTS,
  ALL_PERIOD,
  MONTHLY,
  DATE_INTERVAL,
  ANNUALLY,
} from '@/constants/tyumenOurHome'
import { DEBOUNCE_DELAY_SMALL } from '@/constants/time'
import {
  urbanProcesses,
  chartTypeOptions,
  periodOptions,
  intervalOptions,
  selectOptions,
} from './config'
import Icons from '@/components/icons/users'
import {
  FormContainer,
  ContentContainer,
  Text,
  CustomButton,
  CustomDatePicker,
  FilterContentWrapper,
  Title,
  DateWrapper,
  ButtonsWrapper,
  StyledButton,
  IconContainer,
  Container
} from './styles'

const TyumenOurHomeFilter = ({
  requestGetTyumenGraphs,
  content,
  dateGraph,
  getMessagesListRequest,
  isMobile,
  selectedMessageId,
  data,
  onClose,
  counts,
  type,
  setType
}) => {
  const formicForm = useRef(null)
  const [dateCheck, setDateCheck] = useState(true)
  const [urlValues, setUrlValues] = useUrlParseAndPush()
  const { push } = useHistory()
  const { pathname } = useLocation()
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [openTypePopup, setOpenTypePopup] = useState(null)

  useEffect(() => {
    getMessagesListRequest(urlValues)
  }, [getMessagesListRequest, urlValues])

  useEffect(() => {
    if (!isEmpty(dateGraph)) {
      requestGetTyumenGraphs(urlValues)
    }
  }, [dateGraph, requestGetTyumenGraphs, urlValues])

  const toggleFilterPopup = useCallback(() => {
    setIsOpenPopup(!isOpenPopup)
    setOpenTypePopup('filter')
  }, [isOpenPopup])


  const toggleSortPopup = () => {
    setIsOpenPopup(!isOpenPopup)
    setOpenTypePopup('sort')
  }

  const parsedInitialValues = useMemo(() => {
    const newUrlParse = { ...urlValues }
    newUrlParse[START_DATE] = urlValues[START_DATE] ? new Date(urlValues[START_DATE]) : null
    newUrlParse[END_DATE] = urlValues[END_DATE] ? new Date(urlValues[END_DATE]) : null
    return {
      [PERIOD]: ALL_PERIOD,
      [CHART_TYPE]: ALL_CHARTS,
      [STATUS]: null,
      ...newUrlParse,
    }
  }, [urlValues])

  const showNotification = debounce(() => {
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Измените диапазон или выберите другой интервал',
      type: 'error',
    })
  }, DEBOUNCE_DELAY_SMALL)

  const datePickerFilter = useCallback((formValues) => {
    const startDate = new Date(formValues[START_DATE])
    const endDate = new Date(formValues[END_DATE])
    const dateDifference = moment(formValues[END_DATE]).diff(moment(formValues[START_DATE]), 'days')
    if (formValues[INTERVAL] !== ANNUALLY) {
      if ((dateDifference > DATE_INTERVAL[formValues[INTERVAL]]
          || startDate > endDate)
          && formValues[START_DATE]
          && formValues[END_DATE]
      ) {
        setDateCheck(false)
        showNotification()
      } else {
        setDateCheck(true)
      }
    } else {
      setDateCheck(true)
    }
  }, [showNotification])

  const handleSetValues = useCallback(() => (formValues) =>  {
    if (isMobile) {
      return
    }
    datePickerFilter(formValues)
    if (
      formValues[CHART_TYPE] !== urlValues[CHART_TYPE]
      || formValues[URBAN_PROCESSES] !== urlValues[URBAN_PROCESSES]
      || formValues[STATUS] !== urlValues[STATUS]
    ) {
      if (pathname === TYUMEN_OUR_HOME_GRAPHS_URL) {
        setUrlValues({
          ...urlValues,
          [CHART_TYPE]: formValues[CHART_TYPE],
          [URBAN_PROCESSES]: formValues[URBAN_PROCESSES],
        })
      } else {
        setUrlValues({
          ...urlValues,
          [CHART_TYPE]: formValues[CHART_TYPE],
          [URBAN_PROCESSES]: formValues[URBAN_PROCESSES],
          [STATUS]: formValues[STATUS],
        })
      }
    }
  }, [datePickerFilter, pathname, setUrlValues, urlValues, isMobile])

  const getButtonTitle = () => {
    if (pathname === TYUMEN_OUR_HOME_URL) {
      return <Lang id="tyumenOurHome.graphs" />
    }
    if (pathname === TYUMEN_OUR_HOME_GRAPHS_URL) {
      return <Lang id="tyumenOurHome.appeals" />
    }
  }
  const goTo = () => {
    if (pathname === TYUMEN_OUR_HOME_URL) {
      push({
        pathname: TYUMEN_OUR_HOME_GRAPHS_URL,
        search: qs.stringify({
          [START_DATE]: null,
          [END_DATE]: null,
          [PERIOD]: ALL_PERIOD,
          [INTERVAL]: MONTHLY,
          [CHART_TYPE]: ALL_CHARTS,
          ...urlValues,
        }),
      })
    }
    if (pathname === TYUMEN_OUR_HOME_GRAPHS_URL) {
      push({
        pathname: TYUMEN_OUR_HOME_URL,
        search: qs.stringify({
          [PERIOD]: ALL_PERIOD,
          [INTERVAL]: MONTHLY,
          [STATUS]: null,
          ...urlValues,
        }),
      })
    }
  }

  const handleSetFastInterval = useCallback((formValues) => (fieldName, value) => {
    getFastInterval(formValues, value, formicForm, setUrlValues)
  }, [setUrlValues])

  const handleSetGraphValue = useCallback(() => (fieldName, value) => {
    const comparison = Object.prototype.toString.call(value) === '[object Date]'
      ? value.valueOf() !== new Date(urlValues[fieldName]).valueOf()
      : value !== urlValues[fieldName]
    if (comparison) {
      formicForm.current.setFieldValue(PERIOD, null)
    }
  }, [urlValues])

  const handleBuildChart = useCallback((values) => () => {
    setUrlValues(values)
  }, [setUrlValues])

  const renderButton = useCallback((values) => {
    const startDateValue = values[START_DATE] ? (values[START_DATE] || '').toString() : null
    const endDateValue = values[END_DATE] ? (values[END_DATE] || '').toString() : null
    if (
      (startDateValue !== urlValues[START_DATE]
      || endDateValue !== urlValues[END_DATE]
      || values[INTERVAL] !== urlValues[INTERVAL])
      && values[INTERVAL] && endDateValue && startDateValue && dateCheck
    ) {
      return (
        <CustomButton onClick={handleBuildChart(values)}>
          <Lang id="tyumenOurHome.buildChart" />
        </CustomButton>
      )
    }
    return null
  }, [dateCheck, handleBuildChart, urlValues])

  const handleResetFilter = useCallback(() => {
    formicForm.current.resetForm()
    setUrlValues({
      [START_DATE]: null,
      [END_DATE]: null,
      [PERIOD]: ALL_PERIOD,
      [INTERVAL]: null,
      [CHART_TYPE]: ALL_CHARTS,
      [URBAN_PROCESSES]: urlValues[URBAN_PROCESSES],
    })
  }, [setUrlValues, urlValues])

  const onSubmit = useCallback((formValues) => {
    setUrlValues({
      ...formValues
    })
  }, [setUrlValues])

  const controls = [
    {
      icon: <CoreIcon.SortIcon />,
      name: 'CoreIcon.SortIcon',
      onClick: toggleSortPopup,
      onClose: toggleSortPopup,
    },
    {
      icon: !(urlValues.endDate || urlValues.startDate || urlValues.status || urlValues.interval)
      ? <HeaderIcons.GlobalFilterIcon />
      : <HeaderIcons.ActiveGlobalFilterIcon />,
      name: 'HeaderIcons.GlobalFilterIcon',
      onClick: toggleFilterPopup,
    }
  ]



  const renderForm = () => {
    return (
      <Formik
      ref={formicForm}
      onSubmit={onSubmit}
      initialValues={parsedInitialValues}
      validate={handleSetValues}
      render={({
        handleSubmit,
        values,
        initialValues,
      }) => (
        <FormContainer onSubmit={handleSubmit}>
          <Text title>
            <Lang id="tyumenOurHome.title" />
          </Text>
          <FilterContentWrapper>
            {content}
            <Text appeals onClick={goTo}>
              {getButtonTitle()}
            </Text>
            <ContentContainer>
              <SelectField
                overflow
                largeOptions
                name={URBAN_PROCESSES}
                withSearch
                options={urbanProcesses}
              />
            </ContentContainer>
            {pathname === TYUMEN_OUR_HOME_GRAPHS_URL && (
              <ContentContainer>
                <SelectField
                  overflow
                  largeOptions
                  name={CHART_TYPE}
                  withSearch
                  options={chartTypeOptions}
                />
              </ContentContainer>
            )}
            {pathname === TYUMEN_OUR_HOME_URL && (
              <ContentContainer>
                <SelectField
                  overflow
                  largeOptions
                  name={STATUS}
                  withSearch
                  placeholder={<Lang id="serviceDeskMyCity.status" />}
                  options={selectOptions}
                />
              </ContentContainer>
            )}
            <ContentContainer>
              <SelectField
                largeOptions
                name={PERIOD}
                withSearch
                placeholder={<Lang id="widgetsDashboard.period" />}
                options={periodOptions}
                onAfterChange={handleSetFastInterval(values)}
              />
            </ContentContainer>
            <ContentContainer>
              <ContentContainer datePicker>
                <CustomDatePicker
                  name={START_DATE}
                  maxDate={new Date()}
                  onAfterChange={handleSetGraphValue()}
                />
              </ContentContainer>
              <Text datePicker>
                -
              </Text>
              <ContentContainer datePicker>
                <CustomDatePicker
                  name={END_DATE}
                  maxDate={new Date()}
                  onAfterChange={handleSetGraphValue()}
                />
              </ContentContainer>
            </ContentContainer>
            <ContentContainer>
              <SelectField
                largeOptions
                name={INTERVAL}
                placeholder={<Lang id="tyumenOurHome.interval" />}
                withSearch
                options={intervalOptions}
                onAfterChange={handleSetGraphValue()}
              />
            </ContentContainer>
            {renderButton(values, initialValues)}
            {Object.keys(initialValues).length > 0 && (
              <Text reset onClick={handleResetFilter}>
                <Lang id="serviceDeskMyCity.resetFilter" />
              </Text>
            )}
          </FilterContentWrapper>
        </FormContainer>
      )}
    />
    )
  }

  const renderFormMobile = () => {
    return (
      <Formik
      ref={formicForm}
      onSubmit={onSubmit}
      initialValues={parsedInitialValues}
      validate={handleSetValues}
      render={({
        handleSubmit,
        values,
        initialValues,
      }) => (
        <FormContainer onSubmit={handleSubmit} mobile>
          <FilterContentWrapper mobile>
            <ContentContainer title>
              <Title>
                Фильтр обращений:
              </Title>
              <IconContainer>
                <Icons.CrossIcon onClick={toggleFilterPopup}/>
              </IconContainer>
            </ContentContainer>
            <ContentContainer>
              <Title>
                Период:
              </Title>
                <SelectFieldMobile
                  name={PERIOD}
                  placeholder={<Lang id="widgetsDashboard.period" />}
                  options={periodOptions}
                />
              </ContentContainer>
              <ContentContainer>
                <DateWrapper>
                <ContentContainer datePicker>
                  <CustomDatePicker
                    name={START_DATE}
                    maxDate={new Date()}
                    onAfterChange={handleSetGraphValue()}
                  />
                </ContentContainer>
                <Text datePicker>
                  -
                </Text>
                <ContentContainer datePicker isMobile>
                  <CustomDatePicker
                    name={END_DATE}
                    maxDate={new Date()}
                    onAfterChange={handleSetGraphValue()}
                  />
                </ContentContainer>
                </DateWrapper>
              </ContentContainer>
              <ContentContainer>
              <Title>
                Интервал:
              </Title>
                <SelectFieldMobile
                  multiselect
                  name={INTERVAL}
                  placeholder="Интервал"
                  options={intervalOptions}
                  onAfterChange={handleSetGraphValue()}
                  withSearch
                />
              </ContentContainer>
              <ContentContainer>
              <Title>
                Городской процесс:
              </Title>
                <SelectFieldMobile
                  overflow
                  name={URBAN_PROCESSES}
                  options={urbanProcesses}
                />
              </ContentContainer>
              {pathname === TYUMEN_OUR_HOME_URL && (
                <ContentContainer>
                  <Title>
                Статусы:
              </Title>
                  <SelectFieldMobile
                    multiselect
                    overflow
                    name={STATUS}
                    placeholder={'Все статусы'}
                    options={selectOptions}
                    withSearch
                  />
                </ContentContainer>
              )}
              <ButtonsWrapper>
              <StyledButton usage="reset" onClick={handleResetFilter}>
                <PopupIcons.ResetFilterIcon color="#3D3D3D" />
                <Lang id="globalFilter.buttons.reset" />
              </StyledButton>
              <StyledButton usage="accept" onClick={handleSubmit} >
                <PopupIcons.DoneFilterIcon color="#FFFFFF" />
                <Lang id="globalFilter.buttons.accept" />
              </StyledButton>
            </ButtonsWrapper>
          </FilterContentWrapper>
        </FormContainer>
      )}
    />
    )
  }

  const contentPopup = useMemo(() => {
    if (openTypePopup === 'filter') {
      return renderFormMobile()
    }
    if (openTypePopup === 'sort') {
      return (
        <SortShuterCytiPortal title={'Сортировка обращений:'} onClose={toggleFilterPopup} />
      )
    }
  }, [openTypePopup, toggleFilterPopup])



  if (isMobile) {
    return (
      <Container>
        {selectedMessageId ?
          <HeaderMobile title={`#${data.key}`} onClose={onClose}/>
        :
          <HeaderMobile title={<Lang id="tyumenOurHome.title" />} controls={controls} content={contentPopup} opacyti={true} openPopup={isOpenPopup} withBorder/>
        }
        {content}
        {!selectedMessageId && <SwiperCytiPortal counts={counts} onClick={setType} status={type}/>}
      </Container>
    )
  }

  return (
    renderForm()
  )
}

TyumenOurHomeFilter.propTypes = {
  getMessagesListRequest: pt.func,
  requestGetTyumenGraphs: pt.func,
}

TyumenOurHomeFilter.defaultProps = {
  getMessagesListRequest: noop,
  requestGetTyumenGraphs: noop,
  typeOfObjects: [],
}

export default React.memo(TyumenOurHomeFilter)
