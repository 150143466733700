import React, { useState } from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'

import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable'

import {
  ANALYTICS_ALARMS,
  TYUMEN_OUR_HOME,
  SUPPORT,
} from '@/constants/widgetsShop/names'

import DraggableItemMobile from 'components/blocks/DraggableItemMobile'

import {
  Title,
  Header,
  ColumnsContainer,
  Row,
  ControlBlock,
  WrapperTitile,
  InfoBlock,
  Container,
  WrapperWidgetBlock,
  LabelBlock,
  FooterInfoBlock,
  IconWrapper
} from './styles'

import BackIcon from '@/components/icons/maps/BackIcon'
import Icon from '@/components/icons/mobileIcons/index'
import SwitchButton from '@/components/controls/SwitchButton'


const SettingsDashboard = ({
  onCloseSettingsDashboard,
  widgetsList,
  onUpdateWidgetsList,
  isOpenNotification,
  onSwitchStateNotification,
  onSwitchStateSwiper,
  isActiveSwiper,
   intl
  }) => {


  const [widgetsListSort, setWidgetsListSort] = useState(widgetsList)

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
);

const createTitle = (widget) => {
  const {type} = widget
  switch(type) {
    case ANALYTICS_ALARMS: {
      return <Title>
        <Lang id="widgets.alarmAnalytics" />
        {` «${widget.settings?.name}»`}
      </Title>
    }
    case TYUMEN_OUR_HOME: {
      return <Title>{`Портал Тюмень «${intl.messages[`tyumenOurHome.processes.${widget.settings.id}`]}»`}</Title>

    }
    case SUPPORT: {
      return <Title>{`Сервис Деск «${widget?.settings?.name}»`}</Title>
    }
    default: {
      return <Title>{widget.settings?.name}</Title>
    }
  }
}

const onDeleteWidget = (id) => {
  const updateWidgetsList = widgetsListSort.map((widget) => {
    if (widget.id === id) {
      return {...widget, isActive: false}
    }
    return widget
  })
  onUpdateWidgetsList(updateWidgetsList)
  setWidgetsListSort(updateWidgetsList)
}

const onAddWidget = (id) => {
  const updateWidgetsList = widgetsListSort.map((widget) => {
    if (widget.id === id) {
      return {...widget, isActive: true}
    }
    return widget
  })
  onUpdateWidgetsList(updateWidgetsList)
  setWidgetsListSort(updateWidgetsList)

}

const getWidgetPos = id => widgetsListSort.findIndex(widget => widget.id === id)

const handleDragEnd = (event) => {
  const { active, over } = event
  if (!over?.id) {
    return null
  }
  if (active.id !== over?.id) {
    setWidgetsListSort((widgetsListSort)=> {
      const orinalPos = getWidgetPos(active.id)
      const newPos = getWidgetPos(over.id)
      const widgetList = arrayMove(widgetsListSort, orinalPos, newPos)
      onUpdateWidgetsList(widgetList)
      return widgetList
    })
  }
}

  return (
    <>
      <Header>
          <BackIcon color={'#1061DF'} onClick={onCloseSettingsDashboard}/>
          <Title>
            <Lang id="menu.mainScreenSettings" />
          </Title>
      </Header>
      <Container>  
        <ControlBlock>
          <WrapperTitile>
            <LabelBlock>
              <Lang id="menu.upperBlock" />
            </LabelBlock>
          </WrapperTitile>
          <ColumnsContainer>
          <Row>
            <Title>
              <Lang id="menu.mainApps" />
            </Title>
            <SwitchButton value={isActiveSwiper} onChange={onSwitchStateSwiper}/>
          </Row>
          <Row>
            <Title>
              <Lang id="settingsSidebar.notificationsTitle" />
            </Title>
            <SwitchButton value={isOpenNotification} onChange={onSwitchStateNotification}/>
          </Row>
          </ColumnsContainer>
        </ControlBlock>
          <InfoBlock>
            <Lang id="menu.dragMessage" />
          </InfoBlock>
          <WrapperWidgetBlock>
          {widgetsListSort.filter((widget) => widget.isActive).length !== 0 && (
            <>
              <WrapperTitile>
                <LabelBlock><Lang id="menu.usedElements" /></LabelBlock>
              </WrapperTitile>
              <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                  autoScroll={false}
                  >
                <ColumnsContainer>
                  <SortableContext
                    items={widgetsListSort}
                    strategy={verticalListSortingStrategy}
                    >
                    {widgetsListSort.map((widget) => {
                      if (widget.isActive) {
                        return (
                          <DraggableItemMobile
                            key={widget.id}
                            id={widget.id}
                            movable={true}
                            title={createTitle(widget)}
                            onDeleteWidget={onDeleteWidget}
                          />
                        )
                      }
                      return null
                    })}
                  </SortableContext>
                </ColumnsContainer>
              </DndContext>
            </>
          )}
          {widgetsListSort.filter((widget) => !widget.isActive).length !== 0 && (
          <>
            <WrapperTitile>
              <LabelBlock><Lang id="menu.unusedElements" /></LabelBlock>
            </WrapperTitile>
            <ColumnsContainer>
              {widgetsListSort.map((widget) => {
              if (!widget.isActive) {
                return (
                <Row>
                  <IconWrapper>
                    <Icon.AddIcon onClick={() => onAddWidget(widget.id)} />
                  </IconWrapper>
                  <Title>{createTitle(widget)}</Title>
                </Row>
                )
              }
              return null
              })}
           </ColumnsContainer>
          </>
          )
          }
          </WrapperWidgetBlock>
          <FooterInfoBlock>
            <Lang id="menu.activateMessage" />
          </FooterInfoBlock>
      </Container>
    </>
  )
}

export default React.memo(SettingsDashboard)
