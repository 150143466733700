import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import {
  DASHBOARD_URL,
  SERVICE_DESK_MY_CITY_TABLE_URL,
} from '@/constants/routes'
import useUrlParseAndPush from '@/hooks/useUrlParseAndPush'
import Loader from '@/components/blocks/Loader'
import {
  OVERDUE_ON_EXECUTION,
  AVERAGE_LEAD_TIME,
  STATUSES,
  RECEIVED,
} from '@/constants/serviceDeskMyCity'
import HeaderMobile from '../HeaderMobile'
import {
  SERVICE_DESK,
} from '@/constants/applications'
import config from '../../config'
import Widget from '../Widget'
import WidgetContainer from './styles'
import useGetIsMobile from '@/hooks/useGetIsMobile'

const Statistic = ({
  widgets,
  getInfoCards,
  loading,
  infoCards,
  integrationStatus,
  userApplications,
  currentUser,
}) => {
  const { push } = useHistory()
  const [urlValues, setUrlValues] = useUrlParseAndPush()
  const isMobile = useGetIsMobile()
  const serviceDeskStatus = useMemo(() => (
    (integrationStatus || []).find(
      (status) => (
        currentUser.roles.isSuperAdmin
          ? urlValues.widgetId === status.temporaryId
          : urlValues.widgetId === status.widgetId
      ),
    )
  ), [integrationStatus, currentUser.roles.isSuperAdmin, urlValues.widgetId])

  const serviceDeskId = useMemo(() => {
    const serviceDesk = userApplications.find((application) => application.code === SERVICE_DESK)
    return (serviceDeskStatus && serviceDeskStatus.id)
      || (serviceDesk && JSON.parse(serviceDesk.settings).id)
  }, [serviceDeskStatus, userApplications])

  useEffect(() => {
    serviceDeskId
      ? getInfoCards({
          serviceDeskId,
          serviceDeskConfig: serviceDeskStatus && serviceDeskStatus.propertyConfigContent})
      : push(DASHBOARD_URL)
  }, [getInfoCards, serviceDeskId, serviceDeskStatus, push])

  const handleNavigation = useCallback((value) => () => {
    if (isMobile) {
      return null
    }
    switch (value) {
      case OVERDUE_ON_EXECUTION: {
        setUrlValues({ overdue: true, ...urlValues }, SERVICE_DESK_MY_CITY_TABLE_URL)
        break
      }
      case RECEIVED: {
        setUrlValues({ ...urlValues }, SERVICE_DESK_MY_CITY_TABLE_URL)
        break
      }
      case AVERAGE_LEAD_TIME: {
        setUrlValues({ ...urlValues }, SERVICE_DESK_MY_CITY_TABLE_URL)
        break
      }
      default: {
        setUrlValues({ status: STATUSES[value], ...urlValues }, SERVICE_DESK_MY_CITY_TABLE_URL)
        break
      }
    }
  }, [setUrlValues, urlValues, isMobile])
  const renderWidget = useCallback((widget) => {
    if (infoCards) {
      const findingCard = infoCards.find((card) => card.type === widget.id)
      return (
        <Widget
          key={widget.id}
          {...widget}
          count={findingCard ? findingCard.value : 0}
          onClick={handleNavigation(widget.id)}
        />
      )
    }
    return (
      <Widget
        key={widget.id}
        {...widget}
        onClick={handleNavigation(widget.id)}
      />
    )
  }, [handleNavigation, infoCards])

  return (
    <>
    <HeaderMobile/>
    <WidgetContainer loading={loading}>
      {loading
        ? (<Loader />)
        : widgets.map(renderWidget)}
    </WidgetContainer>
    </>
  )
}

Statistic.defaultProps = {
  widgets: config,
}

export default Statistic
