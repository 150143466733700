import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  max-width: 200px;
  height: 100%;
  max-height: 450px;
  overflow-y: auto;
`

export const OptionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`

export const OptionTitle = styled.div`
  font-size: 1.4rem;
  line-height: 3rem;
  text-align: left;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: 100%;
  border-radius: 2px;
  padding: 5px;
  cursor: pointer;
`

