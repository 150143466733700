import React, {
  useCallback, useMemo,
} from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { useTreeState } from 'react-hyper-tree'
import TreeWrapped from './components/TreeWrapped'

const useMemoTreeState = (params) => {
  const tree = useTreeState(params)

  const memoRequired = useMemo(() => tree.required, [tree.required])
  const memoHandlers = useMemo(() => tree.handlers, [tree.handlers])

  return useMemo(() => ({
    required: memoRequired,
    handlers: memoHandlers,
  }), [memoRequired, memoHandlers])
}

const SidebarTree = ({
  id,
  data,
  searchQuery,
  onSelectNode,
  withSort,
  isMonitoring,
  selectedNode,
  isSchedule,
  checkTree,
  onCheckNode,
  objectsGroupsFilters
}) => {

  const filterTree = useCallback(({
    data: { name },
  }) => (name || '').toLowerCase().includes((searchQuery || '').toLowerCase()), [searchQuery])
  // const sortTree = useCallback((a, b) => {
  //   if (a.name > b.name) { return 1 }
  //   if (a.name < b.name) { return -1 }
  //   return 0
  // }, [])

  // const sort = useMemo(() => (withSort ? sortTree : undefined), [withSort, sortTree])
  const filter = useMemo(() => (searchQuery ? filterTree : null), [searchQuery, filterTree])

  const tree = useMemoTreeState({
    id,
    data: data,
    // sort,
    filter,
    multipleSelect: false,
  })

  return (
    <TreeWrapped
      id={id}
      onSelectNode={onSelectNode}
      tree={tree}
      isMonitoring={isMonitoring}
      selectedNode={selectedNode}
      isSchedule={isSchedule}
      checkTree={checkTree}
      onCheckNode={onCheckNode}
      searchQuery={searchQuery}
    />
  )
}

SidebarTree.propTypes = {
  id: pt.string,
  withSort: pt.bool,
  searchQuery: pt.string,
  onSelectNode: pt.func,
}
SidebarTree.defaultProps = {
  id: 'objects-tree',
  withSort: false,
  searchQuery: '',
  onSelectNode: noop,
}

export default React.memo(SidebarTree)
