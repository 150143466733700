import {
  put, takeLatest, select
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
    getStatistic,
} from '@/helpers/getTelementry'
import { getObjectStatistic } from '@/helpers/dataFormator'
import {
  successGetPinsAndZonesAndTelemetry,
  errorGetPinsAndZonesAndTelemetry,
} from '@/store/actions/installation/getZonesAndPinsAndTelemetry'
import { generateGetParentNode, generateGetParentNodeTelemetry } from '@/constants/apiRoutes'

import { GET_PINS_AND_ZONES_WITH_TELEMENTRY } from '@/store/actions/installation'
import get from 'lodash/get'
import { INSTALLATION_SERVER_TO_LOCAL } from '@/constants/passportization/types'
import createNotifications from '@/helpers/notification'
import { ROOT, GROUP, LINE_SERVER } from '@/constants/objectTypes'
import { getSelectedNode, getGlobalFilters} from '@/store/selectors/appSettings'
import { treeHandlers } from 'react-hyper-tree'

function* getTreeStructureSaga({ payload }) {
  const { rootId, parentTreeId, treeNodeType, objectStates, isMonitoring, pageSize = 999999 } = payload
  try {
    const { asu , objectsTypes, integrations, processes, owners, exploitationOrganizations, ...statuses} = yield select(getGlobalFilters)
    const statusesTrue = Object.keys(statuses).filter((status) => statuses[status])
    const telemetryStatuses = statusesTrue.length === Object.keys(statuses).length ? [] : statusesTrue
    const url = isMonitoring ? generateGetParentNodeTelemetry : generateGetParentNode
    const data = yield request( {
      url: url({
        params: {
          includeAll: true,
          includeParent: false,
          treeNodeType,
          parentId: parentTreeId,
          pageSize,
          integrationTypes: asu,
          installationTypes: objectsTypes,
          integrationIds: integrations,
          cityProcessTypes: processes,
          objectStates,
          telemetryStatuses,
          owners,
          exploitationOrganizations
        }
      }),
      method: 'get',
    })
    const formattedRequestData = data.data.content
      const selectType = (element) => {
          if (!element.parentId) {
              return 'country'
          } else if (element.installationType === GROUP || !element.installationType || !INSTALLATION_SERVER_TO_LOCAL[element.treeNodeType]) {
              return 'UNKNOWN'
          } else {
              return element.installationType || INSTALLATION_SERVER_TO_LOCAL[element.treeNodeType]
          }
      }
    const formattedData = formattedRequestData?.filter((item) => item.installationType !== LINE_SERVER).map(element => (
        {
            ...element,
            location: [
                get(element, 'point.latitude', null),
                get(element, 'point.longitude', null),
            ],
            type: selectType(element),
            elementType: element.parentId ? element.treeNodeType : ROOT,
            rootGeoZone: rootId,
            geoZoneId: element.path[1],
            parentGeoZoneId: element.parentId,
            parentTreeId: element.parentId,
            original: {
                options: {
                    path: `/${element.path.join('/')}/${element.id}`
                }
            },
            status: isMonitoring ? element.telemetryState : element.objectState,
            state: element.objectState,
            system: element.intergationType,
            statistic: isMonitoring ? getObjectStatistic(element) : getStatistic(element.objectStateChildStatistic)
        }
    ))
    yield put(successGetPinsAndZonesAndTelemetry({
      displayElements: formattedData,
    }))

    if (!parentTreeId) {
      const selectedNode = yield select(getSelectedNode)
      const selectedNodePath = get(selectedNode, 'original.options.path', null)
      const tree = treeHandlers.trees['installations-tree']
        if (selectedNodePath && tree && tree.handlers) {
            tree.handlers.setOpenByPath(selectedNodePath)
        }
    }
  } catch (error) {
    yield put(errorGetPinsAndZonesAndTelemetry())
    console.log('function*getTreeStructureSaga -> error', error)
      const toast = createNotifications()
      toast({
          title: 'Ошибка операции!',
          description: 'Не удалось получить данные телеметрии.\nПовторите попытку позже.',
          type: 'error',
      })
  }
}

export default function* root() {
  yield takeLatest(GET_PINS_AND_ZONES_WITH_TELEMENTRY.REQUEST, getTreeStructureSaga)
}
